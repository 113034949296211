import React, {useEffect, useState} from 'react';
import { Layout, Typography, Row, Col, Timeline, Card } from 'antd';
import logo from './logo.png';
import 'antd/dist/reset.css';
import './App.css';
import { useLocation } from 'react-router-dom';
import axios from "axios";

const { Header, Content } = Layout;
const { Title, Text } = Typography;

const App = () => {
  const [order, setOrder] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const fetchOrder = async () => {
      try {
        const searchParams = new URLSearchParams(location.search);
        const customerId = searchParams.get('c');
        const orderId = searchParams.get('o');
        const response = await axios.get(
          `https://tools.lyskraft.in/orders/${customerId}/${orderId}`
        );
        setOrder(response.data);
      } catch (error) {
        console.error('Error fetching order:', error);
      }
    };

    fetchOrder();
  }, []);

  if (!order) {
    return <div>Loading...</div>;
  }

  const { orderNumber, orderDate, total, customer, lines } = order;
  const numberFormat = (value) =>
    new Intl.NumberFormat('en-IN', {
      style: 'currency',
      currency: 'INR'
    }).format(value);
  return (
    <Layout>
      <Header style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'center' }}>
        <img src={logo} alt="Logo" style={{ height: '40px', marginRight: '16px' }} />
      </Header>
      <Content style={{padding: '20px'}}>
        <div className="order-info">
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12} md={6}>
              <Title level={5}>ORDER NUMBER</Title>
              <Text>#{orderNumber}</Text>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Title level={5}>PLACED ON</Title>
              <Text>{orderDate}</Text>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Title level={5}>TOTAL</Title>
              <Text>{numberFormat(total.toFixed(0))}</Text>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Title level={5}>SHIP TO</Title>
              <Text>{customer.name}</Text><br/>
              <Text>{customer.address}</Text>
            </Col>
          </Row>
        </div>
        {lines.map((line, index) => (
            <Card key={index} title={line.name} style={{marginBottom: '20px'}}>
              <div className="order-status">
                <Title level={5} style={{color: 'green', margin: 0, fontWeight: 'bold'}}>{line.status}</Title>
                {line.deliveryDate !== "N/A" && <Text>Estimated Delivery Date: {line.deliveryDate}</Text>}
              </div>
              <Timeline>
                {line.timeline.map((item, timelineIndex) => (
                    <Timeline.Item
                        key={timelineIndex}
                        color={item.active ? 'green' : 'gray'}
                        className="timeline-item"
                    >
                      {item.content.map((content, contentIndex) => (
                          <Text key={contentIndex}>{content}</Text>
                      ))}
                    </Timeline.Item>
                ))}
              </Timeline>
              {/*<Text>*/}
              {/*    <a href={line.trackingLink}>View Tracking</a>*/}
              {/*</Text>*/}
            </Card>
        ))}

        <a href={"https://wa.me/919311084248"} target={"_blank"}>
          <Card>
            <div className="flex-container">
              <div className="flex-items">
                <img height="30"
                     src={"https://upload.wikimedia.org/wikipedia/commons/5/5e/WhatsApp_icon.png?20200503174721"}
                />
              </div>
              <div className="flex-items">
                For any queries please contact us on <a href={"https://wa.me/919311084248"}
                                                        target={"_blank"}>Whatsapp</a>
              </div>
            </div>
          </Card>
        </a>

      </Content>
    </Layout>
);
};

export default App;